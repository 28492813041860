<template>
  <div id="footer-cookie-bar" class="cookie-bar" :class="{ 'mb-[44px]': isFooterAddBagBarActive }">
    <div class="cookie-bar__inner">
      <div class="cookie-bar__close icon77 icon77-exit" @click="$emit('close')" />
      <p class="cookie-bar__content my-1" v-html="cookiePolicyText" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits(['close'])

const { t } = useI18n()
const localePath = useLocalePath()

const cookiePolicyText = computed(() =>
  t(
    'footer-cookie-notice',
    { link_privacy_policy: localePath('/privacy-77diamonds.html') },
    'This site uses cookies: By continuing to browse the site you are agreeing to our use of cookies. Find out more',
  ),
)

const isFooterAddBagBarActive = ref(false)

const checkFooterCookieBar = () => {
  isFooterAddBagBarActive.value = !!document.getElementById('footer-add-bag-bar')
}

onMounted(() => {
  checkFooterCookieBar()

  const observer = new MutationObserver(() => {
    checkFooterCookieBar()
  })

  observer.observe(document.body, { childList: true, subtree: true })

  onBeforeUnmount(() => {
    observer.disconnect()
  })
})
</script>

<style scoped>
.cookie-bar {
  background-color: #f6f6f6;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  height: 40px;
  max-height: 40px;
  width: 100%;
  z-index: 100;
  transition:
    transform 0.5s,
    bottom 0.5s;

  &--inactive {
    transform: translateY(100%);
  }

  &__inner {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    margin: auto;
    padding: 0 26px;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
    height: 70px;
    line-height: 18px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 14px;
    height: 50px;
    line-height: 18px;
  }

  @media (min-width: 769px) and (max-width: 1029px) {
    &__content {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
