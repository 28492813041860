<template>
  <div class="basis-1/3 flex gap-2 items-center justify-end">
    <div
      class="bookAppointment font-plain color-charcoal _d-lg-flex _align-items-lg-center gap-2"
      data-cy="book-appointment"
      @click="$emit('book-an-appointment')"
    >
      <span>{{ $t('dear-user', { 'customer-name': 'kebabkey' }, 'Dear {customer-name},') }}</span>
      <span>{{ $t('dear-user', { 'customer-name': 'kebabkey no fallback' }) }}</span>
      <span>{{ $t('dear-user', { customerName: 'camelkey' }, 'Dear {customerName},') }}</span>
      <span>{{ $t('dear-user', { customerName: 'camelkey no fallback' }) }}</span>
      <span>{{ $t('order-reference', { 'order-reference': 'XXXX' }) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import HeaderAuth from './HeaderAuth.vue'
import { useShoppingBagStore } from '~/store/shoppingBagStore'
import { useSetupTranslations } from '~/composables/useSetupTranslations'

const { setupTranslationsByPageId } = await useSetupTranslations()

await setupTranslationsByPageId(19, 'en')
const { itemsCount } = storeToRefs(useShoppingBagStore())

const runtimeConfig = useRuntimeConfig()

const route = useRoute()

const isBasketPage = computed(() => {
  return route.path === '/shop-bag'
})
</script>

<style scoped lang="scss">
.bookAppointment {
  cursor: pointer;

  .icon77 {
    @media (max-width: 1024px) {
      display: inline-block;
      margin-right: 4px;
    }

    @media (max-width: 767px) {
      margin-right: 0px;
    }
  }

  .bookAppointment-title {
    margin: auto 10px;
    font-size: 13px;
    line-height: initial;
    letter-spacing: 0px;
  }
}

.bagCountHolder {
  vertical-align: -2px;
}
</style>
